import React, { Fragment, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Avatar,
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
}
from '@material-ui/core';

import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';

import AppHeader, { AppHeaderButton } from '../../../../Components/AppHeader/AppHeader';

const useStyles = makeStyles(theme => ({
  margin: {
    marginRight: theme.spacing(2)
  },
  dot: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));


export const getInitials = name => {
  const arr = name.split(' ');
  if (arr.length < 2) {
    return name.charAt(0);
  }

  return `${arr[0].charAt(0)}${arr[1].charAt(0)}`.toUpperCase();
};


const ChatListItem = props => {

  const classes = useStyles();

  return (
    <Fragment>
   <ListItem button alignItems="flex-start" onClick={()=>{props.handleClick()}}>
      <Box component='span' visibility={props.status === 'NEW' ? 'visibile' : 'hidden'}>
      <Badge
        color='error'
        variant='dot'
        badgeContent={1}
        className={classes.dot}/>
      </Box>

      <Avatar color='secondary' className={classes.margin}>{getInitials(props.name)}</Avatar>
      <ListItemText
        primary={props.name}
        secondary={props.message}
        secondaryTypographyProps={{noWrap: true}}/>
      <ListItemIcon >
        <ChevronRightSharpIcon style={{marginLeft: '50px', marginTop: '10px'}} />
      </ListItemIcon>
    </ListItem>
    <Divider/>
  </Fragment>
  );
};

const InboxActions = props => (
  <span>
    <AppHeaderButton onClick={props.onAdd}><AddIcon/></AppHeaderButton>
    <AppHeaderButton onClick={props.onClick}><FilterListIcon/></AppHeaderButton>
  </span>
);

const filteredMessages = (filters, messages) => {
  if (filters === 'ALL') {
    return messages;
  }

  return messages.filter(a => a.status === 'NEW');
};

const ChatList = props => {

  const [filters, setFilters] = useState('UNREAD');

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div style={{height: '100%', paddingBottom: '66px'}}>
      <AppHeader title='Inbox'
        secondaryAction={<InboxActions onAdd={props.onAdd} onClick={()=>{setFilters(filters === 'ALL' ? 'UNREAD' : 'ALL')}}/>}
      />
      <div style={{height: '56px'}}/>
      <div>
        <Typography gutterBottom variant='h5'>{filters === 'UNREAD' ? 'Unread Messages' : 'Last 100 Messages'}</Typography>
        <List dense disablePadding>
          { filteredMessages(filters,props.messages).map(message=>
            <ChatListItem key={message.id} handleClick={()=>{props.onChatClick(message.id)}} name={message.name} message={ message.messages.length === 0 ? '[downloading...]' : message.messages[0].text} status={message.status}/>)}
        </List>
      </div>
    </div>
  );
};

export default ChatList;
