import React, { Fragment, useState } from 'react';

import {Box, Container, Typography, TextField, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ChatIcon from '@material-ui/icons/Chat';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { makeStyles } from '@material-ui/core/styles';

import PhoneInput from '../../../../Components/PhoneInput/PhoneInput';

import { createContact as createContactApi } from '../../../../Services/API';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  icon: {
    color: theme.palette.success.main,
    fontSize: 200
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));


const CreateContact = props => {

  const { token } = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [saved, setSaved] = useState(false);

  const classes = useStyles();

  const reset = () => {
    setName('');
    setEmail('');
    setPhone('');
    setSaved(false);
  }

  const saveContact = () => {

    if(!email || !name || !phone) {
      alert('All fields are required.');
      return;
    }

    const fullPhone = `+1${phone.replace(/[^0-9]/g,'')}`;

    if(fullPhone.length !== 12) {
      setPhone('');
      return alert('Phone Number is invalid');
    }

    createContactApi(token, name, email, fullPhone).then(results => {
      if (results.status !== 'ok') {
        alert('All fields are required.');
      } else {
        setSaved(true);
      }
    });
  };


  if(props.save === true) {
    saveContact();
    setImmediate(()=>props.onSaved());
  }

  return (
    <Container className={classes.container} componet='main' maxWidth='xs'>
        { saved ?
          <Fragment>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Typography variant='h4'>Contact Created</Typography>
              <CheckIcon className={classes.icon}/>
              <Button size='large' startIcon={<ScheduleIcon/>} fullWidth variant='outlined' color='primary'>Schedule</Button>
              <Button className={classes.button} size='large' startIcon={<ChatIcon/>} fullWidth variant='outlined' color='primary'>Message</Button>
              <Button className={classes.button} size='large' onClick={reset} startIcon={<PersonAddIcon/>} fullWidth variant='contained' color='primary'>New Contact</Button>
            </Box>
          </Fragment>
        :
        <Fragment>
          <Typography variant='h4'>Contact Details</Typography>
          <Typography variant='body1'>To create a new contact, enter first and last name, email, and cellphone including area code.</Typography>
          <TextField
            InputLabelProps={{shrink:true}}
            className={classes.textField}
            variant='outlined'
            value={name}
            label='Name'
            margin='normal'
            fullWidth
            onChange={e=>setName(e.target.value)}
          />
          <TextField
            InputLabelProps={{shrink:true}}
            className={classes.textField}
            variant='outlined'
            value={email}
            label='Email'
            fullWidth
            onChange={e=>setEmail(e.target.value)}/>
          <PhoneInput
            fullWidth
            clear={()=>setPhone('')}
            classes={classes}
            value={phone}
            onChange={e=>setPhone(e.target.value)}
          />
        </Fragment>
        }

      </Container>);
};

export default CreateContact;
