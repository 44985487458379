/* global fetch */

import { BASE_URL, get, post, /*del,*/ put } from './request.js';

export const login = (email, password) => {

  const url = BASE_URL + '/login';
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ email, password })
  }

  return fetch(url, options).then(response => response.json());
};

export const resetPassword = email => {
  const url = BASE_URL + '/login/resetPassword';
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ email })
  }

  return fetch(url, options).then(response => response.json());
}

export const setNewPassword = (token, temp, password) => {
  const url = BASE_URL + '/login/resetPassword';
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ token,temp,password })
  }

  return fetch(url, options).then(response => response.json());
}

// This api retrieves an auth token for a specific account
export const getTokenForAccount = (token, accountId) => {
  return post({
    token,
    path: `login/${accountId}`,
  });
};

export const getAccounts = (token) => {
  return get({
    token,
    path: 'accounts'
  });
};

export const getUser = (token) => {
  return get({
    token,
    path: 'user'
  });
};

export const getChats = (token) => {
  return get({
    token,
    path: 'chats'
  });
};

export const getChatData = (token, chatId) => {
  return get({
    token,
    path: `chats/${chatId}`
  });
};

export const createChat = (token, name, phone, customerId) => {
  return post({
    token,
    path: `chats/create`,
    body: JSON.stringify({
      name,
      phone,
      customerId
    })
  });
};

export const sendMessage = (token, message) => {
  return post({
    token,
    path: `chats/message`,
    body: JSON.stringify(message)
  });
};

export const setChatStatus = (token, chatId, status) => {
  return put({
    token,
    path: `chats/${chatId}`,
    body: JSON.stringify({
      status
    })
  });
}

export const getAllContacts = (token) => {
  return get({
    token,
    path: `customers?opt`
  });
};

export const getContact = (token, contactId) => {
  return get({
    token,
    path: `customers/${contactId}`
  });
};

export const createContact = (token, name, email, phone, source = 'Manual') => {

  return post({
    token,
    path: `customers`,
    body: JSON.stringify({
      name,
      email,
      phone,
      source
    })
  });
};

export const updateContact = (token, contactId, name, email, labels = []) => {
  return put({
    token,
    path: `customers/${contactId}`,
    body: JSON.stringify({
      name,
      email,
      labels
    })
  });
};

export const getAppointmentForDate = (token, date = null, end = null) => {
  return get({
    token,
    path: `appointments${date? `?date=${date}`:''}${end ? `&end=${end}`: ''}`
  });
};

export const getAppointmentsForCustomer = (token, customerId) => {
  return get({
    token,
    path: `customers/${customerId}/appointments`
  });
};

export const createAppointment = (token, customerId, name, date) => {
  return post({
    token,
    path: `appointments`,
    body: JSON.stringify({
      customerId,
      name,
      date
    })
  });
};

export const updateAppointment = (token, id, action, date = null) => {
  let body = date ? {date: date.getTime()} : undefined;

  return put({
    token,
    path: `appointments/${id}?action=${action}`,
    body
  });
}

export const getSchedules = token => get({token,path: `schedule`});
