import React, { Fragment, forwardRef, useState, useEffect } from 'react';


import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
}
from '@material-ui/core';

import ScheduleIcon from '@material-ui/icons/Schedule';
import InfoIcon from '@material-ui/icons/Info';


import { getAppointmentsForCustomer as getAppointmentsForCustomerApi, sendMessage as sendMessagApi } from '../../Services/API';

import Messages from './Messages';
import AppHeader, { AppHeaderButton } from '../AppHeader/AppHeader';
import Details from './Details';
import ModalBase from '../Dialogs/ModalBase'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
}));

const ContactActions = props => (
  <span>
    <AppHeaderButton onClick={props.onInfo}><InfoIcon/></AppHeaderButton>
    <AppHeaderButton onClick={props.onSchedule}><ScheduleIcon/></AppHeaderButton>
  </span>
);


const Contact = forwardRef((props, ref) => {
  const { token, contact, user, onEdit } = props;

  const [open, setOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);

  const classes = useStyles();

  const sendMessage = message => {
    sendMessagApi(token, message).then(r => {});
  }

  useEffect(() => {
    getAppointmentsForCustomerApi(token, contact.id).then(r => {
      if (r.status === 'ok') {
        setAppointments(r.appointments);
      }
    });
  }, [token, contact.id]);

  return (
    <div ref={ref} className={classes.root} >
    <AppHeader onBack={props.onBack} title={contact.name}
          secondaryAction={<ContactActions onInfo={()=>setOpen(true)} onSchedule={props.onSchedule}/>}/>
    <ModalBase
     open={open}
     title='Details'
     actions={<Fragment>

        <Button onClick={()=>setOpen(false)} color="primary">
          Close
        </Button>
        <Button variant='contained' onClick={onEdit} color="primary">
          Edit
        </Button>
      </Fragment>}
    >
    <Details contact={contact} appointments={appointments} onSchedule={props.onSchedule}/>
    </ModalBase>
      <Messages chat={contact.chat} user={user} onMessageSend={sendMessage}/>
    </div>
  );
});

export default Contact;
