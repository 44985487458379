import React from 'react';

import {
  Avatar,
  Button,
  Grid,
  Input,
  InputAdornment,
  Typography
}
from '@material-ui/core';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    height: '125px',
    width: '125px',
    fontSize: '3.5em',
    marginBottom: '10px'
  },
  content: {
    textAlign: 'left',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  buttons: {
    textAlign: 'right',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    '& .MuiButton-root:nth-of-type(2)': {
      marginLeft: theme.spacing(1)
    }
  }
}));


function getInitials(name) {
  return name.split(" ").map((n) => n[0]).join("");
}

const Appointments = props => {


  return (
    <div>
{props.appointments.map(a =>
          <div key={a.id}>{new moment(a.date).format('MMM Do, YYYY h:mm A')}, {a.status}</div>
        )}
    </div>
  );
};

const Details = props => {

  const { contact, appointments } = props;

  const classes = useStyles();

  const today = new Date().getTime();

  let current = appointments.filter(a => a.date > today);
  let past = appointments.filter(a => a.date < today);

  return (
    <div className={classes.root}>
      <Grid container direction='column' alignItems='center'>
        <Grid item>
          <Avatar className={classes.avatar}>{getInitials(contact.name)}</Avatar>
        </Grid>
        <Grid item>
          <Typography variant='h5'>{contact.name}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Input
            id="input-with-icon-adornment"
            readOnly
            startAdornment={
              <InputAdornment position="start">
                <PhoneIcon/>
              </InputAdornment>
            }
            value={contact.phone}
          />
        </Grid>
        <Grid item>
          <Input
            id="input-with-icon-adornment"
            readOnly
            startAdornment={
              <InputAdornment position="start">
                <EmailIcon/>
              </InputAdornment>
            }
            value={contact.email}
          />
        </Grid>
      </Grid>

      <div className={classes.content}>
        <Typography variant='h6'>Upcoming Appointments</Typography>
        { current.length > 0 ? <Appointments appointments={current}/> : <span>No upcoming appointments. <Button color='primary' onClick={props.onSchedule} variant='text'>Schedule one?</Button></span> }

        <Typography variant='h6'>Past Appointments</Typography>
        { past.length > 0 ? <Appointments appointments={past}/> : <span>No past appointments.</span> }
      </div>

    </div>
  )
};


export default Details;
