/* global localStorage */
import React, { Fragment, Component, useEffect } from 'react';

import Inbox from './Apps/Inbox/Inbox';
import Appointments from './Apps/Appointments/Appointments';
import ContactList from './Apps/Contacts/ContactList';

import { Box, BottomNavigation, BottomNavigationAction } from '@material-ui/core';

import InboxIcon from '@material-ui/icons/Inbox';
import TodayIcon from '@material-ui/icons/Today';
import PeopleIcon from '@material-ui/icons/People';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import PrimarySearchAppBar from '../../Components/AppBar/AppBar';

import {
  getAccounts as getAccountsApi,
  getUser as getUserApi,
  getTokenForAccount as getTokenForAccountApi,
  getChats as getChatsApi,
  getChatData as getChatDataApi,
  getContact as getContactApi,
  setChatStatus as setChatStatusApi,
}
from '../../Services/API';

import { SOCKET_URL } from '../../Services/API/request';

import AccountChooser from './Dialogs/AccountChooser/AccountChooser';
import EditContact from './Dialogs/EditContact/EditContact';
import CreateContact from './Dialogs/CreateContact/CreateContact';
import NewMessage from './Dialogs/NewMessage/NewMessage';
import BookAppointment from './Dialogs/BookAppointment/BookAppointment';
import Menu from './Menu/Menu';
import DialogBase from '../../Components/Dialogs/DialogBase';

import Contact from '../../Components/Contact/Contact';

const CREATE_CONTACT_ACTION = 'CREATE_CONTACT_ACTION';
const BOOK_APPOINTMENT_ACTION = 'BOOK_APPOINTMENT_ACTION';
const NEW_MESSAGE_ACTION = 'NEW_MESSAGE_ACTION';
const SWICH_ACTION = 'SWITCH_ACCOUNT';
const EDIT_CONTACT_ACTION = 'EDIT_CONTACT_ACTION';
const STATE_VARIABLE = '_main_state';

function saveState(state) {
  localStorage.setItem(STATE_VARIABLE, JSON.stringify(state));
}

function clearState() {
  localStorage.removeItem(STATE_VARIABLE);
}

function loadState() {
  let state = localStorage.getItem(STATE_VARIABLE);
  if (!state) {
    return;
  }
  try {
    state = JSON.parse(state);
    return state;
  }
  catch (e) {
    console.error(e);
  }
}

let socketError = 0;
const WebSocketProvider = props => {

  function initializeSocket() {
    let socket = new WebSocket(SOCKET_URL);

    if (socketError === 10) {
      console.log('Too many socket errors, waiting 10 seconds')
      //stop retrying.
      socket.close();
      socket = null;
      setTimeout(initializeSocket, 10000);
      socketError = 0;
      return;
    }

    socket.addEventListener('error', event => {
      ++socketError;
      socket.close();
    });

    socket.addEventListener('open', event => {
      console.log('Socket connection established');
      socketError = 0;
      var msg = {
        "type": "AUTH",
        "payload": props.token
      };

      //todo: verify auth was successful
      socket.send(JSON.stringify(msg));
    });

    socket.addEventListener('close', event => {
      socket = null;
      setImmediate(initializeSocket);
    });

    socket.addEventListener('message', event => {
      props.onMessage(event.data);
    });
  }

  useEffect(() => {
    initializeSocket();
  }, [])

  return props.children;
};

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      user: null,
      action: '',
      accountToken: '',
      showMenu: false,
      showContact: false,
      contact: null,
      chats: [],
      chatData: null,
      selectedApp: 0,
    }
  }

  refreshAllChats = () => {
    getChatsApi(this.state.accountToken).then(r => {
      this.setState({ chats: r });
    })
  }

  saveState = () => {
    saveState({
      accountToken: this.state.accountToken,
      account: this.state.account,
      user: this.state.user,
      accounts: this.state.accounts
    });
  }

  componentDidMount() {

    const storedState = loadState();

    if (storedState) {
      this.setState({
        ...storedState
      }, this.refreshAllChats);
      return;
    }

    Promise.all([
      getUserApi(this.props.token),
      getAccountsApi(this.props.token),
    ]).then(res => {
      const [userResponse, accountsResponse, chats] = res;
      if (userResponse.status !== 'ok' ||
        accountsResponse.status !== 'ok') {
        return this.props.onLogout();
      }

      const accountData = {
        accountToken: this.props.token,
        account: userResponse.user.account,
        user: userResponse.user,
        accounts: accountsResponse.accounts
      };

      this.setState({
        ...accountData
      }, () => {
        this.saveState();
        this.refreshAllChats()
      });
    });
  }

  handleSocketMessage = message => {
    message = JSON.parse(message);

    const { chats, contact } = this.state;

    if(message.type === 'CHAT_UPDATE') {
      const idx = chats.findIndex(x=>x.id === message.payload.id);
      if(idx === -1) {
        message.payload.messages = [];
        this.setState({chats: [message.payload, ...chats]});
      }
      //todo: handle the case when it's not a new chat...
      return;
    }

    if (message.type !== 'CHAT_MESSAGE') {
      return;
    }

    const chatId = message.payload.chatId;

    const idx = chats.findIndex(x => x.id === chatId);

    if (idx !== -1) {
      chats[idx].messages = [message.payload];
    }

    if (contact && contact.id === chatId) {
      contact.chat.messages.push(message.payload)
    }

    this.setState({ chats, contact });
  }

  handleMenuClick = (name) => () => {
    switch (name) {
      case 'SWITCH':
        this.setState({ action: SWICH_ACTION });
        break;
      case 'LOGOUT':
        clearState();
        this.props.onLogout();
        break;
      default:
        break;
    }
  };

  handleNewAccountSelected = accountId => {
    getTokenForAccountApi(this.props.token, accountId).then(result => {

      if (result.status !== 'ok') {
        return this.props.onLogout();
      }

      this.setState({
        user: result.user,
        account: result.user.account,
        accountToken: result.token,
        action: ''
      }, () => {
        this.saveState();
        this.refreshAllChats()
      });
    });
  };

  menuItems = [
    { name: "Switch Accounts", click: this.handleMenuClick('SWITCH'), icon: <SwapHorizIcon/> },
    { name: "Logout", click: this.handleMenuClick('LOGOUT'), icon: <ExitToAppIcon/> },
  ];

  closeActionDialog = (app) => this.setState({ action: '' });

  showContact = async id => {
    if (!id) {
      return;
    }

    const p1 = getChatDataApi(this.state.accountToken, id);
    const p2 = getContactApi(this.state.accountToken, id);
    const p3 = setChatStatusApi(this.state.accountToken, id, 'OPEN');

    Promise.all([p1, p2, p3]).then(results => {
      const [chat, contact] = results;

      if(chat.httpStatus === 404) {
        console.log(chat);
      }

      const idx = this.state.chats.findIndex(c=>c.id === id);
      if(idx !== -1) {
        this.state.chats[idx].status = 'OPEN';
      }

      this.setState({
        showContact: true,
        action: '',
        chats: this.state.chats,
        contact: { ...contact.customer, chat }
      });
    });
  };

  render() {

    const { showMenu, chats, showContact, contact, accounts, account, accountToken, action, user, selectedApp } = this.state;

    if (!user) {
      return null;
    }

    let app = '';
    let inbox = <Inbox

                  chats={chats}
                  user={user}
                  newMessage={()=>this.setState({action:NEW_MESSAGE_ACTION})}
                  onMessageClick={this.showContact}/>;

    switch (selectedApp) {
      case 0:
        app = inbox;
        break;
      case 1:
        app = <Appointments token={accountToken} user={user} onSchedule={()=>this.setState({action: BOOK_APPOINTMENT_ACTION})}/>;
        break;
      case 2:
        app = <ContactList token={accountToken} user={user} onContactClick={this.showContact} onNewContact={()=>this.setState({action: CREATE_CONTACT_ACTION})}/>;
        break;
      default:
        app = inbox;
    }

    return (
      <WebSocketProvider token={accountToken} onMessage={this.handleSocketMessage}>
        <PrimarySearchAppBar title={account.company} onClick={()=>{this.setState({showMenu: !showMenu})}}/>
          <Menu title={account.company} name={user.name} menuItems={this.menuItems} open={showMenu} close={()=>this.setState({showMenu: false})}/>

          <DialogBase title="Choose Account" open={action === SWICH_ACTION} handleClose={this.closeActionDialog}>
            <AccountChooser accounts={accounts} accountSelected={this.handleNewAccountSelected}/>
          </DialogBase>

          <DialogBase title="Create Contact" open={action === CREATE_CONTACT_ACTION} handleClose={this.closeActionDialog} disabled={this.state.saveContact} actionButton onAction={()=>this.setState({saveContact:true})}>
            <CreateContact user={user} token={accountToken}
              handleSendMessage={contact=>this.showContact(contact.id)} save={this.state.saveContact} onSaved={()=>this.setState({saveContact:false})}/>
          </DialogBase>

          <DialogBase title="New Message" open={action === NEW_MESSAGE_ACTION} handleClose={this.closeActionDialog} >
            <NewMessage user={user} token={accountToken} onMessageSent={cid=>this.showContact(cid)} />
          </DialogBase>

          <DialogBase title="Edit Contact" open={action === EDIT_CONTACT_ACTION} handleClose={this.closeActionDialog}>
            <EditContact contact={contact} token={accountToken} handleClose={this.closeActionDialog}/>
          </DialogBase>

          <DialogBase title="Book Appointment" open={action === BOOK_APPOINTMENT_ACTION} handleClose={this.closeActionDialog}>
            <BookAppointment user={user} contact={contact} token={accountToken} handleClose={this.closeActionDialog}/>
          </DialogBase>

          <div style={{height: '56px'}}/>
            <Box display={showContact ? 'none' : null}>
              { app }
            </Box>
            <Box zIndex='modal' top='0px'>
              { showContact ?
              <Contact user={user}
                token={accountToken}
                contact={contact}
                onBack={()=>this.setState({showContact: false})}
                onEdit={()=>this.setState({action: EDIT_CONTACT_ACTION})}
                onSchedule={()=>this.setState({action: BOOK_APPOINTMENT_ACTION})}
                />: null}
            </Box>


          <BottomNavigation style={{position:'fixed',bottom:0, width: '100%', height: '56px'}} value={selectedApp} showLabels onChange={(e,v)=>{this.setState({selectedApp: v, contact: null, showContact: false})}}>
            <BottomNavigationAction label="Inbox" icon={<InboxIcon/>} />
            <BottomNavigationAction label="Appointments" icon={<TodayIcon/>} />
            <BottomNavigationAction label="Contacts" icon={<PeopleIcon/>} />
          </BottomNavigation>
      </WebSocketProvider>
    );
  }
}

export default Main;
