import React from 'react';
import { Box, Divider, Link, List, ListItem, ListItemText, ListItemIcon, Drawer, Typography } from '@material-ui/core';


export default function Menu(props) {
  return (
    <Drawer open={props.open} onClose={props.close}>
    <Box p={2}>
    <Typography variant='subtitle1'>{props.title}</Typography>
    <Typography variant='subtitle2'>{props.name}</Typography>
    <Divider/>
    <List>
        <ListItem button><ListItemText><Link href='https://app.autorun.fitness'>Autorun v1</Link></ListItemText></ListItem>
      { props.menuItems.map((item,idx)=>
        <ListItem key={idx} button onClick={()=>{props.close(); item.click();}}>
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.name}/>
        </ListItem>) }
    </List>
    </Box>
   </Drawer>
  );
}
