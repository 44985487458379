import React, {useState} from 'react';

import { Button, InputAdornment, OutlinedInput } from '@material-ui/core';


const ChatMessageBar = props => {

    const [text, setText] = useState('');

    function sendMessage() {
      props.sendMessage(text);
      setText('');
    }

    return (
      <OutlinedInput fullWidth variant='outlined'
      value={text}
      onChange={e=>setText(e.target.value)}
      endAdornment={
          <InputAdornment position="end">
            <Button disabled={props.disabled} color='primary' variant='contained' onClick={sendMessage}>Send</Button>
          </InputAdornment>
        }
    />

  );
};

export default ChatMessageBar;