import React, { Fragment, useState, useEffect } from 'react';

import {Box, Container, Typography, TextField, Button, FormControl, Select, InputLabel } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import PhoneInput from '../../../../Components/PhoneInput/PhoneInput';

import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { getContact as getContactApi,
        createContact as createContactApi,
        getSchedules as getSchedulesApi,
        createAppointment as createAppointmentApi,
} from '../../../../Services/API';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  icon: {
    color: theme.palette.success.main,
    fontSize: 200
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

const BookAppointment = props => {

  const { token, user, contact } = props;

  const [name, setName] = useState(contact ? contact.name : '');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [create, setCreate] = useState(false);
  const [enable, setEnable] = useState(contact ? true : false);
  const [contactId, setContactId] = useState(contact ? contact.id : null);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState('');

  const classes = useStyles();

  useEffect(()=> {

    const fullPhone = `+1${phone.replace(/[^0-9]/g,'')}`;

    {/* if(fullPhone.length !== 12 && contactId) {
      setCreate(false);
      setName('');
      setEmail('');
      setContactId(null);
      setEnable(false);
      return;
    } else */} if(fullPhone.length !== 12) {
      return;
    }

    const cid = `${user.account.accountId}${fullPhone}`;
    setContactId(cid);

  }, [phone])

  useEffect(()=> {

    if(!contactId) {
      return;
    }

    getContactApi(token, contactId).then(result=> {
      if(result.status !== 'ok') {
        //this contact was not found, so we need to create it first
        setCreate(true);
      } else {
        setName(result.customer.name);
      }
      setEnable(true);
    });

  }, [contactId]);

  useEffect(()=> {

    getSchedulesApi(token).then(results => {
      if(results.status === 'ok') {
        const t = results.schedules.map(s=>({tag: s.tag, name: s.name}));
        setTags(t);
      }
    })
  },[]);

  const bookAppointment = async () => {

    let cid = contactId;

    if(create) {
      const res = await createContactApi(token, name, email, phone);
      if(res.status !== 'ok') {
        alert('Could not create contact. Try again.');
        return;
      }
      cid = res.id;
    }

    createAppointmentApi(token, cid, name, selectedDate).then(result => {
      console.log(result);
      alert('Appointment booked');
      props.handleClose();
    });
  }


  return (
    <Container className={classes.container} componet='main' maxWidth='xs'>
        <Fragment>
          { contact ? null : <PhoneInput
            fullWidth
            clear={()=>setPhone('')}
            classes={classes}
            value={phone}
            onChange={e=>setPhone(e.target.value)}
          /> }
          { create ?
          <Fragment>
          <Typography variant='h4'>Contact not found</Typography>
          <Typography variant='body1'>Enter in the contact name and email before booking the appointment.</Typography>
          <TextField
            InputLabelProps={{shrink:true}}
            className={classes.textField}
            variant='outlined'
            value={name}
            label='Name'
            margin='normal'
            fullWidth
            onChange={e=>setName(e.target.value)}
          />
          <TextField
            InputLabelProps={{shrink:true}}
            className={classes.textField}
            variant='outlined'
            value={email}
            label='Email'
            fullWidth
            onChange={e=>setEmail(e.target.value)}/>
            </Fragment>
            : null
          }
        <Box mt={2}>
          <Typography gutterBottom>{name}</Typography>
          <Box mt={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              fullWidth
              disabled={!enable}
              label="DateTimePicker"
              inputVariant="outlined"
              value={selectedDate}
              onChange={handleDateChange}
            />
            </MuiPickersUtilsProvider>
          </Box>
          <Box mt={2}>
             <FormControl disabled={!enable} fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel style={{backgroundColor: 'white'}} shrink htmlFor="outlined-age-native-simple">
                Schedule
              </InputLabel>
              <Select
                native
                value={tag}
                onChange={e=>setTag(e.target.value)}
                inputProps={{
                  name: 'age',
                  id: 'outlined-age-native-simple',
                }}
              >
              { tags.map(t=><option key={t.tag} value={t.tag}>{t.name}</option>)}
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <Button fullWidth variant='contained' disabled={!enable} onClick={bookAppointment} color='primary'>Book Appointment</Button>
          </Box>
        </Box>
        </Fragment>

      </Container>);
};

export default BookAppointment;
