import React, { Fragment, useEffect } from 'react';
import uuid from 'uuid';
import { makeStyles } from '@material-ui/core/styles';

import { List, ListItem, ListItemText, Divider, Grid, Typography } from '@material-ui/core';
import formatRelative from 'date-fns/formatRelative'

import ChatMessageBar from '../ChatMessageBar/ChatMessageBar';

const useStyles = makeStyles(theme => {
  //console.log(theme);
  return {
    margin: {
      marginRight: theme.spacing(2)
    },
    button: {
      color: theme.palette.common.white
    },
    listMessageOut: {
      marginRight: theme.spacing(1),
      backgroundColor: 'rgba(90,200,250,1)',
      color: theme.palette.common.white,
      padding: '3px 10px',
      borderRadius: '3px',
      display: 'inline-block',
      maxWidth: '70vw'
    },
    listMessageIn: {
      marginRight: theme.spacing(1),
      backgroundColor: 'rgba(174,174,178,1)',
      color: theme.palette.common.white,
      padding: '3px 10px',
      borderRadius: '3px',
      display: 'inline-block',
      maxWidth: '70vw'
    },
    listMessageARF: {
      marginRight: theme.spacing(1),
      backgroundColor: 'rgba(175,82,222,1)',
      color: theme.palette.common.white,
      padding: '3px 10px',
      borderRadius: '3px',
      display: 'inline-block',
      maxWidth: '70vw'
    },
    messageFooter: {
      position: 'fixed',
      bottom: '56px',
      width: '100%',
      height: '56px',
      backgroundColor: theme.palette.common.white,
      padding: '0px 10px'
    }
  }
});


const IncomingMessage = props => {
  const classes = useStyles();

  return (
    <Fragment>
    <ListItem dense>
        <ListItemText
          primary={<div>{props.name}</div>}
          secondary={props.text}
          secondaryTypographyProps={{className: classes.listMessageIn, variant:'body1'}}
          />
      </ListItem>
      <Typography component='div' style={{marginLeft: '15px', paddingTop: '0px'}} align='left' variant='caption'>
        {formatRelative(new Date(props.time),new Date())}
      </Typography>
    </Fragment>
  );
}

const OutgoingMessage = props => {
  const classes = useStyles();

  return (
    <Fragment>
      <ListItem dense>
        <ListItemText
          primary={<div>{props.name === 'Autorun Slack Hook' ? '🤖 AutoRun Bot' : props.name}</div>}
          secondary={props.text}
          secondaryTypographyProps={{className: (props.name === 'Autorun Slack Hook' ? classes.listMessageARF : classes.listMessageOut), variant:'body1'}}
          />

      </ListItem>
      <Typography component='div' style={{marginLeft: '15px'}} align='left' variant='caption'>
        {formatRelative(new Date(props.time),new Date())}
      </Typography>
    </Fragment>
  );
}

const createMessage = (chatId, text, phone, sender) => ({
  id: uuid.v4(),
  chatId,
  phone,
  text,
  sender,
  messageType: 'message',
  timestamp: new Date().getTime(),
  direction: 'outgoing'
});



const Message = props => {
  const { chat, onMessageSend, user } = props;
  const { phone, id, messages } = chat;

  const classes = useStyles();

  function sendMessage(text) {
    if (!text) {
      return;
    }

    const message = createMessage(id, text, phone, user.name);
    onMessageSend(message);
  }

  useEffect(() => {
    const elm = document.getElementById('ender');
    elm.scrollIntoView(true);
  });

  return (
    <Fragment>
      <div style={{height: '56px'}}/>
      <List>
      <Divider/>
        { messages.map(m=>{
          if(m.direction === 'outgoing') {
            return <OutgoingMessage key={m.id} name={m.sender} time={m.timeStamp} text={m.text} />;
          }

          return <IncomingMessage key={m.id} name={m.sender} time={m.timeStamp} text={m.text} />;

        })}

      </List>
      <div id='ender' style={{marginBottom: '112px'}} />

      <Grid container className={classes.messageFooter} >
        <Grid item xs={12}>
          <ChatMessageBar sendMessage={sendMessage} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Message;
