/* global localStorage */

import React, { Component } from 'react';
import './App.css';
import Login from './Pages/Login/Login.js';
import Main from './Pages/Main/Main.js';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const TOKEN_KEY = '_token';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1976d2'
    },
    secondary: {
      main: '#7c4dff'
    }
  }
});


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLogin: true,
      token: '',
      user: null,
    };
  }

  componentDidMount() {
    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
      this.setState({
        token: token,
        showLogin: false
      });
    }
  }


  saveToken = (token) => {
    this.setState({
      token,
      showLogin: token ? false : true
    }, () => {
      if (token) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      else {
        localStorage.removeItem(TOKEN_KEY);
      }
    });
  }

  render() {

    const { showLogin, token } = this.state;

    return (
      <div className="App">
        <ThemeProvider theme={theme}>
        { showLogin ? <Login onSuccess={token=>this.saveToken(token)} /> :
          <Main token={token} onLogout={()=>this.saveToken(null)}/>
        }
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
