import React, { Fragment, useState, useEffect } from 'react';

import uuid from 'uuid';

import {Box, Container, Typography, TextField, Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import PhoneInput from '../../../../Components/PhoneInput/PhoneInput';
import ChatMessageBar from '../../../../Components/ChatMessageBar/ChatMessageBar';

import { getContact as getContactApi,
        createContact as createContactApi,
        createChat as createChatApi,
        sendMessage as sendMessageApi,
} from '../../../../Services/API';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  icon: {
    color: theme.palette.success.main,
    fontSize: 200
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

const createMessage = (chatId, text, phone, sender) => ({
  id: uuid.v4(),
  chatId,
  phone,
  text,
  sender,
  messageType: 'message',
  timestamp: new Date().getTime(),
  direction: 'outgoing'
});


const NewMessage = props => {

  const { token, user } = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [create, setCreate] = useState(false);
  const [enable, setEnable] = useState(false);
  const [contactId, setContactId] = useState(props.contactId);

  //const [saved, setSaved] = useState(false);

  const classes = useStyles();



  const sendMessage = async text => {

    if(create && (!email || !name || !phone)) {
      alert('All fields are required.');
      return;
    }

    let cid = contactId;
    const fullPhone = `+1${phone.replace(/[^0-9]/g,'')}`;

    if(create) {

      if(fullPhone.length !== 12) {
        setPhone('');
        return alert('Phone number is invalid');
      }

      const result = await createContactApi(token, name, email, fullPhone);

      if(result.status !== 'ok') {
        return alert('All fields are required.');
      }

      cid = result.id;
    }

    // the ui really shouldn't have to know about this
    // but this will have to do for now
    let res = await createChatApi(token, name, fullPhone, cid);

    res = await sendMessageApi(token, createMessage(cid, text, fullPhone, user.name));

    props.onMessageSent(cid);
  };


  useEffect(()=> {
    const fullPhone = `+1${phone.replace(/[^0-9]/g,'')}`;

    if(fullPhone.length !== 12 && contactId) {
      setCreate(false);
      setName('');
      setEmail('');
      setContactId(null);
      setEnable(false);
      return;
    } else if(fullPhone.length !== 12) {
      return;
    }

    const cid = `${user.account.accountId}${fullPhone}`;
    setContactId(cid);

  }, [phone])

  useEffect(()=> {

    if(!contactId) {
      return;
    }

    getContactApi(token, contactId).then(result=> {
      if(result.status === 'ok') {
        setName(result.customer.name);
        setEmail(result.customer.email);
      } else {
        //this contact was not found, so we need to create it first
        setCreate(true);
      }
      setEnable(true);
    });

  }, [contactId]);



  return (
    <Container className={classes.container} componet='main' maxWidth='xs'>
        <Fragment>
          <PhoneInput
            fullWidth
            clear={()=>setPhone('')}
            classes={classes}
            value={phone}
            onChange={e=>setPhone(e.target.value)}
          />
          { create ?
          <Fragment>
          <Typography variant='h4'>Contact not found</Typography>
          <Typography variant='body1'>Enter in the contact name and email before sending the message</Typography>
          <TextField
            InputLabelProps={{shrink:true}}
            className={classes.textField}
            variant='outlined'
            value={name}
            label='Name'
            margin='normal'
            fullWidth
            onChange={e=>setName(e.target.value)}
          />
          <TextField
            InputLabelProps={{shrink:true}}
            className={classes.textField}
            variant='outlined'
            value={email}
            label='Email'
            fullWidth
            onChange={e=>setEmail(e.target.value)}/>
            </Fragment>
            : null
          }
        <Box mt={2}>
          <Typography>{name}</Typography>
          <ChatMessageBar sendMessage={sendMessage} disabled={!enable}/>
        </Box>
        </Fragment>

      </Container>);
};

export default NewMessage;

/*



*/