import React from 'react';

import { FormControl, OutlinedInput , InputLabel, InputAdornment,IconButton } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

import MaskedInput from 'react-text-mask';


function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}


const PhoneInput = props => {

  const {value, onChange, clear} = props;

  return (
     <FormControl variant='outlined' className={props.classes.textField}>
      <InputLabel style={{backgroundColor:'#FFF'}} variant='outlined' shrink htmlFor="text-mask-input">Cell Phone</InputLabel>
      <OutlinedInput
        required
        fullWidth
        inputProps={props.inputProps}
        id='text-mask-input'
        value={value}
        onChange={onChange}
        inputComponent={TextMaskCustom}
        endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={clear}>
                  <ClearIcon/>
                </IconButton>
              </InputAdornment>
            }
      />
    </FormControl>
  );
};

export default PhoneInput;
